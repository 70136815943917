//let accessToken;
let domain;
let WP_BO;
let apiPath;
let WP_accessToken;
let accessToken;

domain = process.env.VUE_APP_DOMAIN;

WP_BO = process.env.VUE_APP_WP_BO;
apiPath = "/wp-json/mso";
WP_accessToken = process.env.VUE_APP_WP_BO_ACCESS_TOKEN;
accessToken = process.env.VUE_APP_AUTH_ACCESS_TOKEN;

export const WPgeneralHeaders = {
  accessToken: WP_accessToken,
};

export const returnType = "/returnType/json";

export const url = {
  getGeneralInfo: WP_BO + apiPath + "/general-info",
  getCarNotFound: domain + "CarNotfound",
  getCombosByPlate: domain + "getVehicleMaintenance_1Identification/plate/",
  getCombosByToken: domain + "getVehicleMaintenance_1Identificationvytoken/",
  getCarByPlate: domain + "getCarByPlate/plate/",
  getDealerByBrand: domain + "getWorkshopInstallationsbyBrand/brand/",
  getCampaignInfo: domain + "getCampaignsInfo",
  getServices: domain + "getService/idInstalation/",
  getMobilityServices1: domain + "getMobilityOptionsMSO/brand/",
  getMobilityServices2: "/IdInstalation/",
  getWashServices: domain + "getWashOption/idInstalation/",
  getAvailability1: domain + "getAvailabilityMSO/plate/",
  getAvailability2: "/km/",
  getAvailability3: "/idInstalation/",
  getAvailability4: "/maintenanceVarId/",
  getAvailability5: "/idMobility/",
  scheduleService: domain + "setMarkingService/plateNr/",
  getCGSession: domain + "GetSessionID",
  getCGLink: domain + "Caetanogo",
  getCGUser: domain + "GetUserCaetanogo",
  getCGVehicles: domain + "GetVehiclesCaetanogo",
  searchUserCaetanoGo: domain + "SearchUserCaetanogo",
  getBackgroundImage: domain + "backgroundImage",
  getCorrectToken: domain + "setCorrectToken",
};

export const headers = {
  accessToken: accessToken,
  "Content-type": "application/json",
  idPortalApp: 116,
};
