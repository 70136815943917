InputLogin.vue

<template>
  <div class="field-wrapper">
    <label>{{ label }}</label>
    <div>
      <input
        :type="type"
        :placeholder="placeholder"
        @input="updateValue($event.target.value)"
        @keyup.13="submit"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "InputLogin",
  props: {
    label: { type: String, default: "" },
    type: { type: String, default: "text" },
    placeholder: { type: String, default: "" },
  },
  methods: {
    updateValue: function (value) {
      this.$emit("updateInput", value);
    },
    submit: function () {
      this.$emit("submit");
    },
  },
};
</script>
<style lang="scss" scoped>
.field-wrapper {
  label {
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1rem;
    margin-bottom: 1rem;
    display: block;
  }

  input {
    width: 300px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #ffffff;
    box-sizing: border-box;
    color: #fff;
    border-radius: 4px;
    margin-bottom: 2rem;
    padding: 0.9rem 0.5rem;
    outline: none;

    &::placeholder {
      font-family: "Montserrat", sans-serif;
      color: #ffffff;
      opacity: 1;
      font-style: normal;
      font-weight: normal;
      font-size: 0.8rem;
    }

    &:-ms-input-placeholder {
      color: #ffffff;
      font-style: normal;
      font-weight: normal;
    }

    &::-ms-input-placeholder {
      color: #ffffff;
      font-style: normal;
      font-weight: normal;
    }
  }
}
</style>
