<template>
   <div v-if="isLoading" id="mso" :style="cssVars">
    <Loading type="screen" />
  </div>
  <div v-else-if="isReady" id="mso" :style="cssVars">
    <router-view />
    <CaetanoGo />
  </div>
  <PopUpFormError
    v-else-if="finalError && !isReady"
    :style="cssVars"
    :noData="true"
  />
  <div v-else id="mso" :style="cssVars">
    <Loading type="screen" />
  </div>
</template>

<script>
import router from "./router";

import { country } from "@/services/country";

import Vue from "vue";
import VueGtm from "@gtm-support/vue2-gtm";

import CaetanoGo from "@/components/CaetanoGo.vue";
import PopUpFormError from "@/components/PopUp/PopUpFormError.vue";
import Loading from "@/components/Loading/Loading.vue";

if (country == "pt") {
  Vue.use(VueGtm, {
    id: "GTM-M6N7H95",
    enabled: true,
    vueRouter: router,
  });
}

export default {
  components: {
    CaetanoGo,
    Loading,
    PopUpFormError,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    faviIcon() {
      return this.$store.state.globalIcons.fivico_icon;
    },
    appleTouchIcon() {
      return this.$store.state.globalIcons.apple_touch_icon;
    },
    isReady() {
      return this.$store.state.appIsReady;
    },
    finalError() {
      return this.$store.state.finalError;
    },
    init() {
      return this.$store.state.init;
    },
    cssVars() {
      return {
        "--primaryColor": this.$store.state.primaryColor
          ? this.$store.state.primaryColor
          : "#008fd1",
        "--secondaryColor": this.$store.state.secondaryColor
          ? this.$store.state.secondaryColor
          : "#008fd1",
        "--primaryTextColor": this.$store.state.primaryTextColor
          ? this.$store.state.primaryTextColor
          : "#ffffff",
        "--secondaryTextColor": this.$store.state.secondaryTextColor
          ? this.$store.state.secondaryTextColor
          : "#ffffff",
      };
    },
    cgVehiclesReady() {
      return this.$store.state.cgVehiclesReady;
    },
    vehicles() {
      return this.$store.state.cgVehicles;
    },
    hasCG() {
      return this.$store.state.hasCG;
    },
    isAuth() {
      return this.$store.state.authenticated;
    },
  },
  mounted() {
    this.isLoading = false;
    window.addEventListener('beforeunload', this.logout);
    //Verifica se o utilizador está na pagina outboundMSO, se estiver e nao estiver autenticado, será redirecionado para a pagina de login.
    if (window.location.href === 'https://outboundmso.caetanoretail.pt/' || window.location.href === 'http://demooutboundmsocaetanoretail.niw.pt/') {
      if (sessionStorage.getItem('isAuth') == 1) {
        this.$router.push({ name: "Homepage" });
      } else {
        this.$router.push({ name: "Login" });
      }
    }
  },
  beforeCreate() {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 20; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    this.$store.dispatch("selectOption", {
      selectedValue: "randomKey",
      value: result,
    });
  },
  created() {
    const path = window.location.pathname;
    if(path != "") {
      const parts = path.split('/').filter(Boolean);
      const firstPart = parts.length > 0 ? parts[0] : "";
      if(firstPart != "") {
        this.$store.commit("setFirstPath", firstPart);
      }
    }
    if (!this.$store.state.dealersReady) {
      this.$store.dispatch("getGeneralInfo", this.hasCG);
      this.getQueryParams();
      this.checkCampaigns();
    }
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.logout);
  },
  methods: {
    //Fazer o update do favicon quando o user alterar no backoffice.
    updateFavicon() {
      const faviconUrl = this.$store.state.globalIcons.fivico_icon
        ? this.$store.state.globalIcons.fivico_icon
        : "public/favicon.ico";

      const link =
        document.querySelector("link[rel~='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "icon";
      link.href = faviconUrl;

      document.getElementsByTagName("head")[0].appendChild(link);
    },
    updateAppleTouchIcon() {
      const appleTouchIconUrl = this.$store.state.globalIcons.apple_touch_icon
        ? this.$store.state.globalIcons.apple_touch_icon
        : "public/favicon.ico";
      const link =
        document.querySelector("link[rel='apple-touch-icon']") ||
        document.createElement("link");
      link.rel = "apple-touch-icon";
      link.href = appleTouchIconUrl;

      document.getElementsByTagName("head")[0].appendChild(link);
    },
    checkCampaigns() {
      if (this.$route.query.idCampaigns) {
        sessionStorage.setItem(
          "idCampaigns",
          this.$route.query.idCampaigns != null
            ? this.$route.query.idCampaigns
            : ""
        );
      }
      if (sessionStorage.getItem("idCampaigns") != null) {
        this.$store.dispatch("selectOption", {
          selectedValue: "hasCampaigns",
          value: true,
        });
        this.$store.dispatch("selectOption", {
          selectedValue: "idCampaigns",
          value: sessionStorage.getItem("idCampaigns"),
        });
      }
    },
    getQueryParams() {
      const params = [
        "url",
        "site",
        "oid_newsletter",
        "utm_campaign",
        "utm_source",
        "utm_medium",
        "utm_content",
        "utm_term",
      ];

      params.forEach((param) => {
        if (this.$route.query[param]) {
          sessionStorage.setItem(
            param,
            this.$route.query[param] != null ? this.$route.query[param] : ""
          );
        }
        if (sessionStorage.getItem(param) != null) {
          this.$store.dispatch("selectOption", {
            selectedValue: param,
            value: sessionStorage.getItem(param) ?? "",
          });
        }
      });
    },
    logout() {
      this.$store.commit('LOGOUT');
      sessionStorage.removeItem('isAuth');
      this.$router.replace({ name: "Login" });
    },
  },
  watch: {
    //verificar se teve mudança do favicon na store
    faviIcon(newFaviconUrl) {
      this.updateFavicon(newFaviconUrl);
    },
    //verificar se teve mudança do appleTouchIcon na store
    appleTouchIcon(newAppleTouchIconUrl) {
      this.updateAppleTouchIcon(newAppleTouchIconUrl);
    },
    cgVehiclesReady() {
      if (this.cgVehiclesReady) {
        if (
          this.vehicles.length > 0 &&
          this.$route.name != "CarSelection" &&
          this.$store.state.avancarCarSelection == true
        ) {
          this.$router.push({ name: "CarSelection" });
        }
      }
    },
    isReady() {
      this.$store.dispatch("getCaetanoGoInfo");
    },
    isAuth() {
      sessionStorage.setItem('isAuth', this.isAuth);
      if (this.isAuth == 1) {
        if (this.$route.name != "Homepage") {
          this.$router.replace({ name: "Homepage" });
        }
      } else {
        if (this.$route.name != "Login") {
          this.$router.replace({ name: "Login" });
        }
      }
    },
  },
};
</script>

<style lang="scss">
[v-cloak] {
  display: none;
  color: #fff
}
#mso {
  margin: 0;
  padding: 0;
  height: 100vh;

  > div {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
}

html {
  box-sizing: border-box;

  //Anchor smooth scroll, counts the size of the fixed menu
  scroll-behavior: smooth;
  scroll-padding-top: $menuHeightLg;

  @include respond-to-max("md") {
    scroll-padding-top: $menuHeightSm;
  }

  //Text more readable
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  //General CSS for text
  font-family: $primaryFont;
  color: $textColor;
  font-size: $mainFontSize;
  font-weight: $regular;
  line-height: $lineHeight;
  word-break: break-word;
  text-align: center;

  //CSS to ensure the footer stays in the bottom, even when the page is smaller than the viewport
  display: flex;
  flex-direction: column;

  position: relative;

  //CSS Page Size
  min-height: 100vh;
  margin: 0 auto;
  max-width: $maxViweportLG;
  width: 100%;

  @include respond-to-max("md") {
    width: 100%;
  }
}
</style>
