<template>
  <button :id="id" v-if="isLoading == id" :class="btnClass + 'btn disabled'">
    <Loading />
  </button>
  <button v-else :id="id" :class="btnClass + 'btn'" @click="action()">
    {{ label }}
  </button>
</template>

<script>
import Loading from "@/components/Loading/Loading.vue";

export default {
  name: "Button",
  props: {
    btnClass: {
      type: String,
      default: "",
    },
    label: {
      type: String,
    },
    id: {
      type: String,
    },
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  components: {
    Loading,
  },
  methods: {
    action() {
      this.$emit("action");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Buttons";
</style>
