<template>
  <div :class="resumeClass">
    <div v-if="dealerName != '' && dealer" class="row">
      <p class="sm-text">{{ translateString("dealer") }}</p>
      <div class="row">
        <p class="sm-text-highlight">{{ dealerName }}</p>
        <EditIcon :isButton="true" @action="goTo('DealerSelection')" />
      </div>
    </div>
    <p v-if="totalDisclaimer != '' && services" class="disclaimer">
      {{ totalDisclaimer }}
    </p>
    <div v-if="totalServices !== '' && services" class="row">
      <p class="sm-text">{{ translateString("services") }}</p>
      <div class="row">
        <p class="sm-text-highlight">
          <!-- Tenho o 4 argumento "UpdatedResume", serve para entrar em um condição em function exclusivo
          para este componente UpdatedResume.vue -->
          {{
            formatPrice(this.totalServicesManutations, 2, "€", "UpdatedResume")
          }}
        </p>
        <EditIcon :isButton="true" @action="goTo('Services')" />
      </div>
    </div>
    <div
      v-if="mobilityServiceIDSelected.length > 0 != '' && mobility"
      class="row"
    >
      <p class="sm-text">{{ translateString("mobility") }}</p>
      <div class="row">
        <p class="sm-text-highlight">
          {{ formatPrice(mobilityTotalServices, 2, "€") }}
        </p>
        <EditIcon :isButton="true" @action="goTo('MobilityServices')" />
      </div>
    </div>
    <div v-if="washServiceIDSelected.length > 0 && wash" class="row">
      <p class="sm-text margin-text">{{ translateString("wash") }}</p>
      <div class="row">
        <p class="sm-text-highlight">
          {{ formatPrice(washTotalServices, 2, "€") }}
        </p>
        <EditIcon :isButton="true" @action="goTo('WashServices')" />
      </div>
    </div>
    <div v-if="availabilitySelected.day != '' && schedule" class="row">
      <p class="sm-text">{{ translateString("dateHour") }}</p>
      <div class="row">
        <p class="sm-text-highlight">
          {{ date }} às {{ availabilitySelected.hour }}
        </p>
        <EditIcon :isButton="true" @action="goTo('Schedule')" />
      </div>
    </div>
    <div v-if="totalServices !== '' && services" class="row">
      <p class="sm-text">
        {{ translateString("totalValueServices") }}
      </p>
      <div class="row">
        <p class="sm-text-highlight">
          <!-- Tenho o 4 argumento "UpdatedResume", serve para entrar em um condição em function exclusivo
          para este componente UpdatedResume.vue -->
          {{ formatPrice(totalServices, 2, "€", "UpdatedResume") }}
        </p>
        <EditIcon :isButton="true" @action="goTo('Services')" />
      </div>
    </div>
  </div>
</template>

<script>
import { country } from "@/services/country";

import dayjs from "dayjs";
import "dayjs/locale/es";
import "dayjs/locale/pt";

import EditIcon from "@/assets/icons/EditIcon.vue";

import { formatPrice, translateString } from "@/functions/functions.js";

import { mapGetters } from "vuex";

export default {
  name: "UpdatedResume",
  components: {
    EditIcon,
  },
  props: {
    screen: { type: String, default: "" },
    dealer: { type: Boolean, default: false },
    services: { type: Boolean, default: false },
    mobility: { type: Boolean, default: false },
    wash: { type: Boolean, default: false },
    schedule: { type: Boolean, default: false },
  },
  data() {
    return {
      dealerName: this.$store.state.dealerSelected.dealerName,
      washTotalServices: this.$store.state.washServicesTotal,
      mobilityTotalServices: this.$store.state.mobilityServicesTotal,
      totalServices: this.$store.state.servicesTotal,
      mobilityServiceIDSelected: this.$store.state.mobilityServiceIDSelected,
      washServiceIDSelected: this.$store.state.washServiceIDSelected,
      availabilitySelected: this.$store.state.availabilitySelected,
      totalServicesManutations: this.$store.state.updatableServicesTotal,
      resumeClass: "",
      date: "",
    };
  },
  computed: {
    ...mapGetters(["getCurrentStepIndex"]),
    steps() {
      return this.$store.state.steps;
    },
    currentStepIndex() {
      return this.$store.state.currentStepIndex;
    },
    totalDisclaimer() {
      let disclaimer = "";

      if (this.$store.state.totalDisclaimer.length > 0) {
        disclaimer += "* ";
        this.$store.state.totalDisclaimer.forEach((el) => {
          disclaimer += el + ", ";
        });

        disclaimer = disclaimer.slice(0, -2);

        disclaimer += " " + translateString("notIncludedInPrice");
      }

      return disclaimer;
    },
  },
  created() {
    if (this.screen == "mobile") {
      this.resumeClass = "updated-resume-mobile elm-mobile";
    } else if (this.screen == "desktop") {
      this.resumeClass = "updated-resume-desktop elm-desktop";
    }
    this.date = dayjs(this.availabilitySelected.day)
      .locale(country)
      .format("dddd, DD MMMM YYYY");

      this.loadTranslations();
  },
  methods: {
    translateString,
    loadTranslations() {
      // carrega as strings traduzidas vinda da state store STEPS...
      const translatedNames = [
        translateString("init"),
        translateString("vehicle"),
        translateString("dealer"),
        translateString("services"),
        translateString("WashServices"),
        translateString("MobilityServices"),
        translateString("Schedule"),
        translateString("ContactInfo"),
        translateString("Resume"),
      ];
      this.$store.commit("updateStepNames", translatedNames);
    },
    //Tenho este switch case para quando o user clicar no icone editar, o header STEPS sejam atualizados tambem.
    goTo(page) {
      let targetIndex;
      switch (page) {
        case "WashServices":
          targetIndex = 3;
          break;
        case "MobilityServices":
          targetIndex = 3;
          break;
        case "Services":
          targetIndex = 3;
          break;
        case "Schedule":
          targetIndex = 4;
          break;
        case "ContactInfo":
          targetIndex = 5;
          break;
        default:
          targetIndex = this.steps.findIndex((step) => step.route === page);
      }

      this.$store
        .dispatch("setCurrentStepIndex", targetIndex)
        .then(() => {
          this.$router.push({ name: page });
        })
        .catch((error) => {
          console.error("Erro ao despachar setCurrentStepIndex:", error);
        });
    },
    truncate(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
    formatPrice,
  },
};
</script>

<style lang="scss" scoped>
@import "/UpdatedResume";
</style>
