<template>
  <div class="loading-container" :class="this.class">
    <div class="lds-dual-ring"></div>
  </div>
</template>
<script>
export default {
  name: "Loading",
  props: {
    type: {
      type: String,
      default: "button",
    },
  },
  data() {
    return {
      class:
        this.type == "button"
          ? "loading-container--button"
          : "loading-container--screen",
    };
  },
};
</script>
<style lang="scss" scoped>
.loading-container {
  display: flex;

  .lds-dual-ring {
    display: inline-block;
    width: calc($bigFontSize + 5.2px);
    height: calc($bigFontSize + 5.2px);
    margin: 0 auto;

    &:after {
      content: " ";
      display: block;
      width: calc($bigFontSize + 2px);
      height: calc($bigFontSize + 2px);
      border-radius: 50%;
      border: 3px solid var(--secondaryTextColor);
      border-color: var(--secondaryTextColor) transparent
        var(--secondaryTextColor) transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
  }

  &--screen {
    justify-content: center;

    .lds-dual-ring {
      width: 100px;
      height: 100px;
      &:after {
        width: 100px;
        height: 100px;
        border: 10px solid var(--primaryColor);
        border-color: var(--primaryColor) transparent var(--primaryColor)
          transparent;
      }
    }
  }
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
