<template>
  <div :class="classButton + 'svg-edit-container'" @click="action()">
    <span class="tooltip" v-if="!isMobile">Alterar Seleção</span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.664"
      height="11.664"
      viewBox="0 0 11.664 11.664"
    >
      <path
        d="M5.944,2.243,1.794,6.5,0,11.664,5.159,9.982l4.15-4.15Zm5.271-.336L9.757.449a1.36,1.36,0,0,0-2.019,0L6.5,1.682,9.87,5.159l1.346-1.346A1.422,1.422,0,0,0,11.664,2.8,1.385,1.385,0,0,0,11.215,1.907Z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    iconColor: {
      type: String,
      default: "#FFFFFF",
    },
    isButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      classButton: this.isButton ? "btn-icon " : "",
      isMobile: window.matchMedia("(max-width: 768px)").matches,
    };
  },
  created() {
    window.addEventListener("resize", this.updateIsMobile);
  },
  destroyed() {
    window.removeEventListener("resize", this.updateIsMobile);
  },
  methods: {
    action() {
      this.$emit("action");
    },
    updateIsMobile() {
      this.isMobile = window.matchMedia("(max-width: 768px)").matches;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Icons";
</style>
