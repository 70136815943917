import Vue from "vue";
import Vuex from "vuex";
import { authenticator } from "@/auth/auth.js";

import { country } from "@/services/country";

import {
  capitalizeValues,
  getCampaignInfo,
  mapMaintenanceIdsForCampaigns,
} from "../services/dataManagement";
import {
  loadAll,
  loadAvailability,
  loadCaetanoGoInfo,
  loadCaetanoGoLink,
  loadCampaign,
  loadCarCombo,
  loadCarInfo,
  loadCarInfoByPlate,
  loadCorrectToken,
  loadData,
  loadMobilityServices,
  loadServices,
  loadWPInfo,
  makeAppointment,
  searchUserCaetanoGo,
} from "../services/functions";
import { translateString } from "../functions/functions";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    /*LOGIN INFO*/
    userInfo: "",
    authenticated: 0,

    hasServiceSchedule: false,
    navigatedFromHomepage: false,
    isServiceIdMinusOne: false,
    isLoading: false,
    navigatedFromHeader: false,
    enterMyVehicles: false,
    avancarCarSelection: false,
    randomKey: "",
    defaultSelectedDate: "",
    isNewAppointment: false,

    idBrand: "",
    companyName: "",
    brandLogo: "",
    institutionalLogo: "",
    linkWebsite: "",
    primaryColor: "",
    secondaryColor: "",
    primaryTextColor: "",
    secondaryTextColor: "",
    homepageTitle: "",
    seoTitle: "",
    seoDescription: "",
    appIsReady: false,
    sessionToken: "",
    backgroundImage: "",
    backgroundImageMobile: "",
    seoImage: "",
    globalIcons: "",

    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_term: "",
    utm_content: "",
    site: "",
    origin: "",
    oid_newsletter: "",

    hasCampaigns: false,
    idCampaigns: "",
    campaign: [],
    urlCaetanoGo:
      country === "es"
        ? "https://www.caetanogo.es/"
        : "https://www.caetanogo.pt/",
    sessionCG: "",
    hasCG: false,
    cgVehicles: [],
    cgError: false,
    cgVehiclesReady: false,
    cgName: "",
    cgEmail: "",
    cgPhone: "",

    currentStepIndex: 1,

    selectedReferences:[],
    allReady: false,
    brandNF: [],
    modelNF: [],
    bodyNF: [],
    fuelNF: [],
    versionNF: [],
    carNF: {
      brand: "",
      model: "",
      body: "",
      fuel: "",
      version: "",
      token: "",
      plate: "",
      kms: "",
    },

    callError: "",
    finalError: false,

    carName: "",
    carPlate: "",
    carImage: "",
    carKms: "",
    carByPlate: [],
    carCombo: [],
    carComboNFReady: false,

    carByPlateReady: false,
    carComboSelected: [],

    dealersByBrand: [],
    dealersByBrandReady: false,
    dealersFiltered: [],
    dealerSelected: [],
    lastLocation:
      country === "es"
        ? { lat: 40.4250645318076, lng: -3.703577602816887 }
        : { lat: 38.8988928, lng: -9.0472448 },

    servicesSelected: {
      services: [],
      subServices: [],
    },
    markingService: null,
    markingServiceSelected: [],
    selectedIds: [],

    servicesTotal: "",
    totalDisclaimer: [],
    updatableServicesTotal: "",

    services: [],
    allServicesSelected: [],
    servicesSelectedCompletedList: [],
    workIds: [],
    maintenanceList: [],
    complementaryList: [],
    referenceList: [],
    servicesReady: false,

    mobilityServices: [],
    mobilityServicesReady: false,
    mobilityServiceSelected: {},
    mobilityServiceIDSelected: [],
    mobilityServicesTotal: 0,
    mobilityServicesOldTotal: 0,

    washServices: [],
    washServicesReady: false,
    washServiceSelected: {},
    washServiceIDSelected: [],
    washServicesTotal: 0,
    washServicesOldTotal: 0,

    availability: [],
    availabilityReady: false,
    availabilitySelected: {
      day: "",
      hour: "",
    },
    contactInfo: {
      name: "",
      email: "",
      phone: "",
      createAccount: false,
      acceptRGPD: false,
      consentCenter: false,
    },
    obs: "",

    firstPath: "",

    scheduleReady: false,
    errorFormReady: -1,
    selectedCarKtype: null,
    selectedCarKtypeInfo: null,
    steps: [
      { name: "init", route: "Homepage" },
      { name: "vehicle", route: "CarSelection" },
      { name: "dealer", route: "DealerSelection" },
      { name: "services", route: "Services" },
      { name: "WashServices", route: "WashServices" },
      { name: "MobilityServices", route: "MobilityServices" },
      { name: "Schedule", route: "Schedule" },
      { name: "ContactInfo", route: "ContactInfo" },
      { name: "Resume", route: "Resume" },
    ],
    canonicalUrls: {
      "devoficinascaetanoretail.rigorcg.pt": "https://devoficinascaetanoretail.rigorcg.pt",
      "demooficinascaetanoretail.rigorcg.pt": "https://demooficinascaetanoretail.rigorcg.pt",
      "demooficinascaetanoretail.rigorcg.pt_byd": "https://demooficinascaetanoretail.rigorcg.pt",
      "oficinas.caetanoretail.pt": "https://oficinas.caetanoretail.pt",
      "oficinas.caetanoretail.pt_byd": "https://oficinas.caetanoretail.pt/byd",
      "oficinas.caetanoretail.pt_lexus": "https://oficinas.caetanoretail.pt/lexus",
      "oficinas.caetanoretail.pt_toyota": "https://oficinas.caetanoretail.pt/toyota",
      "oficinas.caetanoretail.pt_bmw": "https://oficinas.caetanoretail.pt/bmw",
      "oficinas.caetanoretail.pt_mini": "https://oficinas.caetanoretail.pt/mini",
      "oficinas.caetanoretail.pt_volkswagen": "https://oficinas.caetanoretail.pt/volkswagen",
      "oficinas.caetanoretail.pt_hyundai": "https://oficinas.caetanoretail.pt/hyundai",
      "oficinas.caetanoretail.pt_dacia": "https://oficinas.caetanoretail.pt/dacia",
      "oficinas.caetanoretail.pt_renault": "https://oficinas.caetanoretail.pt/renault",
      "oficinas.caetanoretail.pt_opel": "https://oficinas.caetanoretail.pt/opel",
      "oficinas.caetanoretail.pt_peugeot": "https://oficinas.caetanoretail.pt/peugeot",
      "oficinas.caetanoretail.pt_nissan": "https://oficinas.caetanoretail.pt/nissan",
      "oficinas.caetanoretail.pt_audi": "https://oficinas.caetanoretail.pt/audi",
      "oficinas.caetanoretail.pt_mercedes": "https://oficinas.caetanoretail.pt/mercedes",
      "oficinas.caetanoretail.pt_smart": "https://oficinas.caetanoretail.pt/smart",
      "oficinas.caetanoretail.pt_skoda": "https://oficinas.caetanoretail.pt/skoda",

      "oficinas.caetanotec.pt": "https://oficinas.caetanoretail.pt/byd",
      "oficinas.caetanoautolexus.pt": "https://oficinas.caetanoautolexus.pt",
      "oficinas.caetanoautotoyota.pt": "https://oficinas.caetanoautotoyota.pt",
      "oficinas.caetanobavierabmw.pt": "https://oficinas.caetanobavierabmw.pt",
      "oficinas.caetanobavieramini.pt": "https://oficinas.caetanobavieramini.pt",
      "oficinas.caetanodrive.pt": "https://oficinas.caetanodrive.pt",
      "oficinas.caetanoenergy.pt": "https://oficinas.caetanoenergy.pt",
      "oficinas.caetanoformuladacia.pt": "https://oficinas.caetanoformuladacia.pt",
      "oficinas.caetanoformularenault.pt": "https://oficinas.caetanoformularenault.pt",
      "oficinas.caetanomotorsopel.pt": "https://oficinas.caetanomotorsopel.pt",
      "oficinas.caetanomotors.pt": "https://oficinas.caetanomotors.pt",
      "oficinas.caetanopower.pt": "https://oficinas.caetanopower.pt",
      "oficinas.caetanosport.pt": "https://oficinas.caetanosport.pt",
      "oficinas.caetanostarmercedes.pt": "https://oficinas.caetanostarmercedes.pt",
      "oficinas.caetanostarsmart.pt": "https://oficinas.caetanostarsmart.pt",
      "oficinas.caetanourban.pt": "https://oficinas.caetanourban.pt",

      "cita-taller.caetanoretail.es": "https://cita-taller.caetanoretail.es",
      "cita-taller.caetanoretail.es_nissan": "https://cita-taller.caetanoretail.es/nissan",
    },
    backLinkSeo: {
      "devoficinascaetanoretail.rigorcg.pt": "https://www.caetanoretail.pt",
      "demooficinascaetanoretail.rigorcg.pt": "https://www.caetanoretail.pt",
      "demooficinascaetanoretail.rigorcg.pt_byd": "https://www.caetanotec.pt",
      "oficinas.caetanoretail.pt": "https://www.caetanoretail.pt",

      "oficinas.caetanoretail.pt_byd": "https://www.caetanotec.pt",
      "oficinas.caetanoretail.pt_lexus": "https://www.caetanoautolexus.pt",
      "oficinas.caetanoretail.pt_toyota": "https://www.caetanoautotoyota.pt",
      "oficinas.caetanoretail.pt_bmw": "https://www.caetanobavierabmw.pt",
      "oficinas.caetanoretail.pt_mini": "https://www.caetanobavieramini.pt",
      "oficinas.caetanoretail.pt_volkswagen": "https://www.caetanodrive.pt",
      "oficinas.caetanoretail.pt_hyundai": "https://www.caetanoenergy.pt",
      "oficinas.caetanoretail.pt_dacia": "https://www.caetanoformuladacia.pt",
      "oficinas.caetanoretail.pt_renault": "https://www.caetanoformularenault.pt",
      "oficinas.caetanoretail.pt_opel": "https://www.caetanomotorsopel.pt",
      "oficinas.caetanoretail.pt_peugeot": "https://www.caetanomotors.pt",
      "oficinas.caetanoretail.pt_nissan": "https://www.caetanopower.pt",
      "oficinas.caetanoretail.pt_audi": "https://www.caetanosport.pt",
      "oficinas.caetanoretail.pt_mercedes": "https://www.caetanostarmercedes.pt",
      "oficinas.caetanoretail.pt_smart": "https://www.caetanostarsmart.pt",
      "oficinas.caetanoretail.pt_skoda": "https://www.caetanourban.pt",

      "oficinas.caetanotec.pt": "https://www.caetanotec.pt",
      "oficinas.caetanoautolexus.pt": "https://www.caetanoautolexus.pt",
      "oficinas.caetanoautotoyota.pt": "https://www.caetanoautotoyota.pt",
      "oficinas.caetanobavierabmw.pt": "https://www.caetanobavierabmw.pt",
      "oficinas.caetanobavieramini.pt": "https://www.caetanobavieramini.pt",
      "oficinas.caetanodrive.pt": "https://www.caetanodrive.pt",
      "oficinas.caetanoenergy.pt": "https://www.caetanoenergy.pt",
      "oficinas.caetanoformuladacia.pt": "https://www.caetanoformuladacia.pt",
      "oficinas.caetanoformularenault.pt": "https://www.caetanoformularenault.pt",
      "oficinas.caetanomotorsopel.pt": "https://www.caetanomotorsopel.pt",
      "oficinas.caetanomotors.pt": "https://www.caetanomotors.pt",
      "oficinas.caetanopower.pt": "https://www.caetanopower.pt",
      "oficinas.caetanosport.pt": "https://www.caetanosport.pt",
      "oficinas.caetanostarmercedes.pt": "https://www.caetanostarmercedes.pt",
      "oficinas.caetanourban.pt": "https://www.caetanourban.pt",

      "cita-taller.caetanoretail.es": "https://www.caetanoretail.es",
      "cita-taller.caetanoretail.es_nissan": "https://www.caetanoretail.es",
    },
    //Dpois ao inves de ter isto, teremos dados vindo do serviço, isso vai deixar de existir.

  },
  getters: {
    getDealerSelected: (state) => {
      return state.dealerSelected;
    },
    getIsNewAppointment(state) {
      return state.isNewAppointment;
    },
    //Aqui será para verificar sempre que o price de um produto for negativo, neste caso em especifico, se for -2
    getHasServiceWithNegativePrice: (state) => {
      return state.servicesSelectedCompletedList.some((group) =>
        group.services.some((service) => service.price == -2)
      );
    },
    getCampaignInfo: (state) => {
      if (state.dealerSelected && state.dealerSelected.dealerID) {
        return getCampaignInfo(
          state.campaign,
          state.idCampaigns,
          state.dealerSelected.dealerID,
          state.carByPlate.idbrand
        );
      }
    },
    getSelectedCarKtype: (state) => {
      return state.selectedCarKtype;
    },
    getSelectedCarKtypeInfo: (state) => {
      //antes era  return state.carByPlate.ps;
      return state.selectedCarKtypeInfo;
    },

    getSelectedCar: (state) => {
      return state.selectedCar;
    },
    getMappedServices: (state) => {
      return mapMaintenanceIdsForCampaigns(state.services);
    },
    getMappedMobilityServices: (state) => {
      return mapMaintenanceIdsForCampaigns(
        state.services,
        state.mobilityServices
      );
    },
    getMappedWashServices: (state) => {
      return mapMaintenanceIdsForCampaigns(state.services, state.washServices);
    },
    getCurrentStepIndex: (state) => state.currentStepIndex,
    getSelectedReferences: (state) => state.selectedReferences
  },
  mutations: {
    UPDATE_FAVICON(state, faviconUrl) {
      state.globalIcons.fivico_icon = faviconUrl;
    },
    CLEAR_MARKING_SERVICE(state) {
      state.markingService = null;
      Vue.set(state, 'markingServiceSelected', null);
    },
    AUTHENTICATOR(state, userInfo) {
      state.userInfo = userInfo;
      state.authenticated = 1;
    },
    AUTH_FAIL(state) {
      state.authenticated = -1;
    },
    LOGOUT(state) {
      state.authenticated = 0;
    },
    SET_IS_NEW_APPOINTMENT(state, value) {
      state.isNewAppointment = value;
    },
    resetAvailabilitySelected(state) {
      state.availabilitySelected = { day: "", hour: "" };
    },
    SET_SELECTED_SERVICES(state, services) {
      state.allServicesSelectedIds = services;
    },

    SET_SELECTED_REFERENCES(state , references){
      state.selectedReferences = references;
    },

    SET_HAS_SERVICE_SCHEDULE(state, value) {
      state.hasServiceSchedule = value;
    },

    setSelectedDealer(state, dealer) {
      state.dealerSelected = dealer;
    },
    //armazenar os dados dos serviços agendados vindos do serviço
    SET_MARKING_SERVICE(state, markingService) {
      state.markingService = markingService;
    },
    SET_MARKING_SERVICE_SELECTED(state, vehicleIdMarkingService) {
      // Atualizar o estado usando Vue.set para garantir a reatividade
      Vue.set(state, 'markingServiceSelected', vehicleIdMarkingService);
    },
    SET_SELECTED_ITEM: (state, payload) => {
      state.selectedItem = payload;
    },
    SET_SELECTED_MARKING_SERVICE(state, vehicleId) {
      state.selectedVehicle = vehicleId;
    },
    RESET_CARBYPLATE(state) {
      state.carByPlateReady = false;
    },
    // variavel para verificar se o user esta em HomePage para clicar em minhas viaturas quando tem CaetanoGo ready.
    SET_NAVIGATED_FROM_HOMEPAGE(state, value) {
      state.navigatedFromHomepage = value;
    },
    //Utilizado para resetar os preços das lavagens quando atualizamos um checkbox no washServices e ele entra no popup de alterar opcao.
    RESET_WASH_TOTALS(state) {
      state.servicesTotal = 0;
      state.washServicesTotal = 0;
      state.washServiceSelected = [];
      state.washServiceIDSelected = [];
    },
    //atualizar o preço das lavagens ao clicar em um check
    updateWashServicesTotal(state, value) {
      state.washServicesTotal = value;
    },
    //Mutation para traduções dos nomes das rotas que utilizo em updateResume quando clicamos no editarIcon
    updateStepNames(state, names) {
      state.steps.forEach((step, index) => {
        step.name = names[index];
      });
    },
    CLEAR_MOBILITY_SERVICES(state) {
      state.availability = [];
      state.availabilityReady = false;
      state.availabilitySelected = { day: "", hour: "" };
      state.mobilityServiceSelected = [];
      state.mobilityServiceIDSelected = [];
      state.mobilityServicesTotal = 0;
      state.mobilityServicesOldTotal = 0;
    },
    CLEAR_WASH_SERVICES(state) {
      state.washServiceSelected = [];
      state.washServiceIDSelected = [];
      state.washServicesTotal = 0;
      state.washServicesOldTotal = 0;
      state.mobilityServices = [];
      state.mobilityServicesReady = false;
      state.mobilityServiceSelected = [];
      state.mobilityServiceIDSelected = [];
      state.mobilityServicesTotal = 0;
      state.mobilityServicesOldTotal = 0;
    },
    RESET_SELECTED_SERVICES(state) {
      state.services = [];
      state.allServicesSelected = [];
      state.servicesSelectedCompletedList = [];
      state.workIds = [];
      state.maintenanceList = [];
      state.complementaryList = [];
      state.referenceList = [];
      state.servicesReady = false;

      if (state.washServicesTotal > 0) {
        state.servicesTotal -= state.washServicesTotal;
      }
      if (state.mobilityServicesTotal > 0) {
        state.servicesTotal -= state.mobilityServicesTotal;
      }

      state.washServiceSelected = [];
      state.washServiceIDSelected = [];
      state.washServicesTotal = 0;
      state.washServicesOldTotal = 0;

      state.mobilityServices = [];
      state.mobilityServicesReady = false;
      state.mobilityServiceSelected = [];
      state.mobilityServiceIDSelected = [];
      state.mobilityServicesTotal = 0;
      state.mobilityServicesOldTotal = 0;

      state.availability = [];
      state.availabilityReady = false;
      state.availabilitySelected = { day: "", hour: "" };
    },
    UPDATE_DEALER_ID(state, dealerId) {
      if (state.dealerSelected) {
        state.dealerSelected.dealerID = dealerId;
      }
    },
    UPDATE_DEALER_SELECTED(state, dealerSelected) {
      state.dealerSelected = dealerSelected;
    },
    SET_NAVIGATED_FROM_HEADER(state, value) {
      state.navigatedFromHeader = value;
    },
    SET_ENTER_MY_VEHICLES(state, value) {
      state.enterMyVehicles = value;
    },
    SET_AVANCAR_CAR_SELECTION(state, value) {
      state.avancarCarSelection = value;
    },
    //utilizado na condicao para nao mostrar o popup quando o serviceId for -1
    SET_SERVICE_ID_MINUS_ONE(state, value) {
      state.isServiceIdMinusOne = value;
    },
    //CHANGES THE STATES OF THE PROPERTY WITH THE ARRAY AND IF IT'S READY
    GET_GENERALINFO(state, response) {
      state.idBrand = response.idBrand;
      state.companyName = response.companyName;
      state.brandLogo = response.brandLogo;
      state.institutionalLogo = response.institutionalLogo;
      state.linkWebsite = response.linkWebsite;
      state.primaryColor = response.primaryColor;
      state.secondaryColor =
        response.secondaryColor == ""
          ? response.primaryColor
          : response.secondaryColor;
      state.primaryTextColor = response.primaryTextColor;
      state.secondaryTextColor =
        response.secondaryTextColor == ""
          ? response.primaryTextColor
          : response.secondaryTextColor;
      state.homepageTitle = response.homepageTitle;
      state.seoTitle = response.seoTitle;
      state.seoDescription = response.seoDescription;
      state.sessionToken = response.sessionToken;
      state.seoImage = response.seoImage ? response.seoImage : "";
      state.backgroundImage = response.backgroundImage
        ? response.backgroundImage
        : "";
      state.backgroundImageMobile = response.backgroundImageMobile
        ? response.backgroundImageMobile
        : "";
      state.globalIcons = response.globalIcons ? response.globalIcons : "";
      //sessionStorage.setItem("sessionToken", response.sessionToken);
    },
    SET_SELECTED_CAR_KTYPE(state, value) {
      state.selectedCarKtype = value.value;
    },
    SET_SELECTED_CAR_KTYPE_INFO(state, value) {
      state.selectedCarKtypeInfo = value;
    },
    SET_SELECTED_CAR(state, car) {
      state.selectedCar = car;
    },
    GET_CAMPAIGN(state, data) {
      state.campaign = data;
    },
    GET_SESSION(state, id) {
      state.appIsReady = true;
      state.sessionCG = id;
    },
    GET_CGERROR(state) {
      state.cgError = true;
    },
    GET_USERCGINFO(state, info) {
      state.hasCG = true;
      if (info.basic) {
        state.cgName = info.basic.name;
        state.cgEmail = info.basic.email;
      }
      if (info.personal_data) {
        state.cgPhone = info.personal_data.phone_number_full;
      }
    },
    GET_CGVEHICLES(state, info) {
      state.cgVehicles = info;
      state.cgVehiclesReady = true;
    },
    GET_ALL(state, data) {
      state[data.value] = data.data.notFound.obj;
      state.allReady = true;

      if (data.value == "modelNF") {
        state.bodyNF = [];
        state.fuelNF = [];
        state.versionNF = [];
        state.carNF.model = "";
        state.carNF.body = "";
        state.carNF.fuel = "";
        state.carNF.version = "";
        state.carNF.token = "";
      } else if (data.value == "bodyNF") {
        state.fuelNF = [];
        state.versionNF = [];
        state.carNF.body = "";
        state.carNF.fuel = "";
        state.carNF.version = "";
        state.carNF.token = "";
      } else if (data.value == "fuelNF") {
        state.versionNF = [];
        state.carNF.fuel = "";
        state.carNF.version = "";
        state.carNF.token = "";
      } else if (data.value == "versionNF") {
        state.carNF.version = "";
        state.carNF.token = "";
      }
    },
    GET_PLATEKMSBYUSER(state, data) {
      state.carPlate = data.plate;
      state.carKms = data.kms;
    },
    GET_CARBYPLATE(state, data) {
      state.carByPlateReady = true;
      state.carByPlate = capitalizeValues(data.carInfo);

      let brand = state.carByPlate.brand ? state.carByPlate.brand : "";

      const model = state.carByPlate.model ? state.carByPlate.model : "";
      const typeModel = state.carByPlate.typeModel
        ? state.carByPlate.typeModel
        : "";

      state.carImage =
        state.carByPlate.photo != "" && state.carByPlate.photo != undefined
          ? "data:image/png;base64," + state.carByPlate.photo
          : require("@/assets/car_placeholder.png");
      state.carName = brand + " " + model + " " + typeModel;
      state.carCombo = data.carCombo;
    },
    GET_CARCOMBONF(state, data) {
      state.carCombo = data;
      state.carImage = require("@/assets/car_placeholder.png");
      state.carComboNFReady = true;
    },
    GET_DEALERSBYBRAND(state, dealersByBrand) {
      state.dealersByBrandReady = true;
      state.dealersByBrand = dealersByBrand;
    },
    GET_SERVICES(state, services) {
      state.services = services;
      state.servicesReady = true;
    },
    GET_MOBILITYSERVICES(state, services) {
      state.mobilityServices = services;
      state.mobilityServicesReady = true;
    },
    GET_WASHSERVICES(state, services) {
      state.washServices = services;
      state.washServicesReady = true;
    },
    GET_AVAILABILITY(state, response) {
      state.availability = response;
      state.availabilityReady = true;
    },

    SELECTOPTION(state, selection) {
      if (selection.obj) {
        state.carNF[selection.selectedValue] = selection.value;
      } else {
        state[selection.selectedValue] = selection.value;
      }
    },

    CALL_ERROR(state, error) {
      state.isLoading = false;
      switch (error) {
        case -1:
          state.finalError = true;
          break;
        case 0:
          state.callError = "";
          break;
        case 1:
          state.callError = "Resultados não encontrados.";
          break;
        case 2:
          state.callError = "Sem horários disponíveis.";
          break;
        case 3:
          state.callError =
            "Matrícula não encontrada, por favor verifique se está correta.";
          break;
        case 4:
          state.callError = translateString("brandNotSupported");
      }
    },
    //Limpar as variaveis para ter calculos e eventos corretos quando o user clicar em back no header ou no link da barra de progresso.
    CLEAR_SELECTEDINFO(state, page) {
      switch (page) {
        case "MobilityServices":
          this.dispatch("clearMobilityServices", state);
          break;
        case "WashServices":
          this.dispatch("clearWashServices", state);
          break;
        case "Services":
          this.dispatch("clearServices", state);
          this.dispatch("clearMobilityServices");
          this.dispatch("clearWashServices");
          break;
        case "DealerSelection":
          this.dispatch("clearDealerSelection", state);
          break;
        case "Homepage":
          this.dispatch("clearHomepage");
          break;
        case "Reset":
          break;

        default:
          break;
      }
    },
    CLEAR_HOME_PAGE(state) {
      state.carName = "";
      state.carByPlate = [];
      state.carByPlateReady = false;
      state.carCombo = [];
      state.carComboSelected = [];
      state.dealersByBrand = [];
      state.dealersByBrandReady = false;
      state.dealerSelected = [];
      state.dealersFiltered = [];
      /* state.currentStepIndex = 1; */
      state.lastLocation =
        country === "es"
          ? { lat: 40.4250645318076, lng: -3.703577602816887 }
          : { lat: 38.8988928, lng: -9.0472448 };
      state.carComboNFReady = false;
      state.allReady = false;
      state.brandNF = [];
      state.modelNF = [];
      state.bodyNF = [];
      state.fuelNF = [];
      state.versionNF = [];
      state.carNF = {
        brand: "",
        model: "",
        body: "",
        fuel: "",
        version: "",
        token: "",
        plate: "",
        kms: "",
      };
    },
    CLEAR_AVAILABILITY(state) {
      state.dealerSelected = [];
      state.dealersByBrand = [];

      state.availability = []; //aqui
      state.availabilitySelected = {
        day: "",
        hour: "",
      };
    },
    updateCurrentStepIndex(state, newIndex) {
      state.currentStepIndex = newIndex;
    },
    setFirstPath(state, path) {
      state.firstPath = path;
    },
  },
  actions: {
    updateFavicon({ commit }, faviconUrl) {
      commit("UPDATE_FAVICON", faviconUrl);
    },
    //resetar o markingServiceSelected
    clearMarkingService({ commit }) {
      commit("CLEAR_MARKING_SERVICE");
    },
    //Login Actions
    authenticateLogin({ commit }, login) {
      authenticator(login).then((response) => {
        if (response.code == 0) {
          commit("AUTHENTICATOR", response.intranetUser);
        } else if (response.code < 0) {
          commit("AUTH_FAIL");
        }
      });
    },
    logout({ commit }) {
      commit("LOGOUT");
    },

    resetAvailabilitySelected({ commit }) {
      commit("resetAvailabilitySelected");
    },
    setSelectedServices({ commit }, services) {
      commit("SET_SELECTED_SERVICES", services);
    },
    setSelectedReferences({ commit }, references) {
      commit("SET_SELECTED_REFERENCES", references);
    },
    setHasServiceSchedule({ commit }, value) {
      commit("SET_HAS_SERVICE_SCHEDULE", value);
    },
    resetHasServiceSchedule({ commit }) {
      commit("SET_HAS_SERVICE_SCHEDULE", null);
    },
    //Item selecionado na view de serviços agendados
    setUpdateSelectedMarkingService({ commit }, vehicleIdMarkingService) {
      commit("SET_MARKING_SERVICE_SELECTED", vehicleIdMarkingService);
    },
    //************************************************/
    resetCarByPlate({ commit }) {
      commit("RESET_CARBYPLATE");
    },
    setNavigatedFromHomepage({ commit }, value) {
      commit("SET_NAVIGATED_FROM_HOMEPAGE", value);
    },
    setAvancarCarSelection({ commit }, value) {
      commit("SET_AVANCAR_CAR_SELECTION", value);
    },
    setEnterMyVehicles({ commit }, value) {
      commit("SET_ENTER_MY_VEHICLES", value);
    },
    setNavigatedFromHeader({ commit }, value) {
      commit("SET_NAVIGATED_FROM_HEADER", value);
    },
    clearMobilityAndWashServices({ commit, state }) {
      if (state.mobilityServicesTotal > 0) {
        state.servicesTotal -= state.mobilityServicesTotal;
        commit("CLEAR_MOBILITY_SERVICES");
      }
      if (state.washServicesTotal > 0) {
        state.servicesTotal -= state.washServicesTotal;
        commit("CLEAR_WASH_SERVICES");
      }
    },
    clearMobilityServices({ commit, state }) {
      if (state.mobilityServicesTotal > 0) {
        state.servicesTotal -= state.mobilityServicesTotal;
      }
      commit("CLEAR_MOBILITY_SERVICES");
    },
    clearWashServices({ commit, state }) {
      if (state.washServicesTotal > 0) {
        state.servicesTotal -= state.washServicesTotal;
      }
      if (state.mobilityServicesTotal > 0) {
        state.servicesTotal -= state.mobilityServicesTotal;
      }
      commit("CLEAR_WASH_SERVICES");
    },
    clearServices(state) {
      state.servicesTotal -=
        state.washServicesTotal - state.mobilityServicesTotal;

      state.washServices = [];
      state.washServicesReady = false;
      state.washServiceSelected = [];
      state.washServiceIDSelected = [];
      state.washServicesTotal = 0;
      state.washServicesOldTotal = 0;
    },
    clearDealerSelection(state) {
      state.servicesSelected = {
        services: [],
        subServices: [],
      };
      state.servicesTotal = 0;
      state.updatableServicesTotal = 0;
      state.totalDisclaimer = [];
      state.currentStepIndex = 2;

      state.services = [];
      state.allServicesSelected = [];
      state.servicesSelectedCompletedList = [];
      state.workIds = [];
      state.maintenanceList = [];
      state.complementaryList = [];
      state.referenceList = [];
      state.servicesReady = false;
    },
    clearHomepage({ commit }) {
      commit("CLEAR_HOME_PAGE");
    },
    CLEAR_AVAILABILITY(state) {
      state.dealerSelected = [];
      state.dealersByBrand = [];

      state.availability = []; //aqui
      state.availabilitySelected = {
        day: "",
        hour: "",
      };
    },
    updateCurrentStepIndex(state, newIndex) {
      state.currentStepIndex = newIndex;
    },

    //CALLS ASYNC AXIOS FUNCTION, AND WHEN IT GETS A RESPONSE CALLS THE MUTATION
    getGeneralInfo({ commit, state }) {
      loadWPInfo(state).then((response) => {
        if (!response) {
          commit("CALL_ERROR", -1);
          setTimeout(() => {
            commit("CALL_ERROR", 0);
          }, 5000);
        } else if (response.status == 200) {
          commit("GET_GENERALINFO", response.data);
          loadCaetanoGoLink(state).then((response) => {
            if (!response) {
              commit("CALL_ERROR", -1);
              setTimeout(() => {
                commit("CALL_ERROR", 0);
              }, 5000);
            } else {
              if (response) {
                commit("GET_SESSION", response.data);
              }
            }
          });
        } else {
          commit("CALL_ERROR", -1);
          setTimeout(() => {
            commit("CALL_ERROR", 0);
          }, 5000);
        }
      });
    },
    selectCarKtype({ commit }, value) {
      commit("SET_SELECTED_CAR_KTYPE", value);
    },
    selectCarKtypeInfo({ commit }, value) {
      commit("SET_SELECTED_CAR_KTYPE_INFO", value);
    },
    getCaetanoGoInfo({ commit, state }) {
      loadCaetanoGoInfo(state).then((response) => {
        if (!response) {
          commit("GET_CGERROR");
        } else {
          if (response[0]) {
            if (response[0].data.code == 0) {
              commit("GET_USERCGINFO", response[0].data.list.data);
            }
          }
          if (response[1]) {
            if (response[1].data.code == 0) {
              commit("GET_CGVEHICLES", response[1].data.list);
            }
          }
        }
      });
    },
    getAll({ commit, state }, object) {
      loadAll(object, state).then((response) => {
        if (!response) {
          commit("CALL_ERROR", -1);
          setTimeout(() => {
            commit("CALL_ERROR", 0);
          }, 5000);
        } else {
          if (object == "token") {
            const selection = {
              selectedValue: "token",
              value: response.vehicle.token,
              obj: "carNF",
            };
            commit("SELECTOPTION", selection);
          } else {
            commit("GET_ALL", {
              value: object + "NF",
              data: response,
            });
          }
        }
      });
    },
    getCarByPlate({ commit, state }, data) {
      commit("CALL_ERROR", 0);
      commit("GET_PLATEKMSBYUSER", {
        plate: data.plate,
        kms: data.kms,
      });

      loadCarInfo(state, data.plate).then((infoResponse) => {
        if (!infoResponse) {
          commit("CALL_ERROR", -1);
          setTimeout(() => {
            commit("CALL_ERROR", 0);
          }, 5000);
        } else if (infoResponse.data.code == 0) {
          commit("SET_MARKING_SERVICE", infoResponse.data.markingService);
          const brand = infoResponse.data.vehicle.brand ? infoResponse.data.vehicle.brand : "";
          if(country == "pt" || (country == "es" && brand.toUpperCase() == "NISSAN")) {
            loadCarCombo(state, data.plate).then((comboResponse) => {
              if (!comboResponse) {
                commit("GET_CARBYPLATE", {
                  carInfo: infoResponse.data.vehicle,
                  carCombo: "",
                  kms: data.kms,
                });
              } else if (comboResponse.data.code == 0) {
                commit("GET_CARBYPLATE", {
                  carInfo: infoResponse.data.vehicle,
                  carCombo: comboResponse.data.typeVehicle,
                  kms: data.kms,
                });
              } else {
                commit("GET_CARBYPLATE", {
                  carInfo: infoResponse.data.vehicle,
                  carCombo: "",
                  kms: data.kms,
                });
              }
            });
          } else {
            commit("CALL_ERROR", 4);
          }
        } else {
          loadAll("brand", state).then((response) => {
            if (!response) {
              commit("CALL_ERROR", -1);
            } else {
              commit("GET_ALL", {
                value: "brandNF",
                data: response,
              });
            }
          });
        }
      });
    },
    async getCarByToken({ commit, state }) {
      await loadCarInfoByPlate(state).then((response) => {
        if (!response) {
          commit("CALL_ERROR", -1);
          setTimeout(() => {
            commit("CALL_ERROR", 0);
          }, 5000);
        } else if (response.code == 0) {
          commit("GET_CARCOMBONF", response.typeVehicle);
        }
      });
    },
    getDealerByBrand({ commit, state }, brand) {
      loadData(brand, "getDealerByBrand", state).then((response) => {
        if (!response) {
          commit("CALL_ERROR", -1);
          setTimeout(() => {
            commit("CALL_ERROR", 0);
          }, 5000);
        } else if (response.code == 0) {
          commit("GET_DEALERSBYBRAND", response.list);
        } else {
          commit("CALL_ERROR", 1);
          setTimeout(() => {
            commit("CALL_ERROR", 0);
          }, 5000);
        }
      });
    },
    async getCorrectToken({ state }) {
      if (!state.selectedCarKtype) {
        console.error("selectedCarKtype is undefined");
        return;
      }
      try {
        const response = await loadCorrectToken(
          state,
          state.selectedCarKtype,
          state.carPlate
        );
        if (response && response.status === 200) {
          console.log(state.selectedCarKtype);
          state.carNF.token = response.data.vehicle.token;
          state.carNF.plate = response.data.vehicle.plate;
        } else {
          console.error("Erro ao obter o token correto:", response);
        }
      } catch (error) {
        console.error("Erro ao obter o token correto:", error);
      }
    },
    getServices({ state, commit }, dealer) {
      const idCampaigns = state.hasCampaigns ? state.idCampaigns : "";
      loadServices(dealer, state, idCampaigns).then((response) => {
        if (!response) {
          commit("CALL_ERROR", -1);
          setTimeout(() => {
            commit("CALL_ERROR", 0);
          }, 5000);
        } else if (response.data.code == 0) {
          commit("GET_SERVICES", response.data.list);
        } else {
          commit("CALL_ERROR", 1);
          setTimeout(() => {
            commit("CALL_ERROR", 0);
          }, 5000);
        }
      });
      if (state.hasCampaigns) {
        loadCampaign(state, idCampaigns).then((response) => {
          if (!response) {
            commit("CALL_ERROR", -1);
            setTimeout(() => {
              commit("CALL_ERROR", 0);
            }, 5000);
          } else if (response.data.code == 0) {
            commit("GET_CAMPAIGN", response.data.list);
          } else {
            commit("CALL_ERROR", 1);
            setTimeout(() => {
              commit("CALL_ERROR", 0);
            }, 5000);
          }
        });
      }
    },
    getMobilityServices({ state, commit }) {
      loadMobilityServices(
        state,
        state.carByPlate.brand,
        state.dealerSelected.dealerID
      ).then((response) => {
        if (!response) {
          commit("CALL_ERROR", -1);
          setTimeout(() => {
            commit("CALL_ERROR", 0);
          }, 5000);
        } else if (response.code == 0) {
          commit("GET_MOBILITYSERVICES", response.mobilityOptions);
        } else {
          commit("CALL_ERROR", -1);
          setTimeout(() => {
            commit("CALL_ERROR", 0);
          }, 5000);
        }
      });
    },
    getWashServices({ state, commit }) {
      loadData(state.dealerSelected.dealerID, "getWashServices", state).then(
        (response) => {
          if (!response) {
            commit("CALL_ERROR", -1);
            setTimeout(() => {
              commit("CALL_ERROR", 0);
            }, 5000);
          } else if (response.code == 0) {
            commit("GET_WASHSERVICES", response.washOptions);
          } else {
            commit("CALL_ERROR", -1);
            setTimeout(() => {
              commit("CALL_ERROR", 0);
            }, 5000);
          }
        }
      );
    },
    getAvailability({ commit, state }) {
      loadAvailability(state).then((response) => {
        if (!response) {
          commit("GET_AVAILABILITY", []);
        } else if (response.code == 0) {
          commit("GET_AVAILABILITY", response.availabilityDayTime);
        } else {
          commit("GET_AVAILABILITY", []);
        }
      });
    },
    searchUserCaetanoGo({ commit, state }) {
      const loading = {
        selectedValue: "isLoading",
        value: false,
      };
      searchUserCaetanoGo(state).then((response) => {
        let hasCG = {
          selectedValue: "hasCG",
          value: false,
        };
        if (response && response.code == 0) {
          hasCG = {
            selectedValue: "hasCG",
            value: true,
          };
        }
        commit("SELECTOPTION", hasCG);
        commit("SELECTOPTION", loading);
      });
    },
    makeAppointment({ commit, state }) {
      makeAppointment(state).then((response) => {
        if (!response) {
          commit("CALL_ERROR", -1);
          setTimeout(() => {
            commit("CALL_ERROR", 0);
          }, 5000);
          commit("SELECTOPTION", {
            selectedValue: "errorFormReady",
            value: 0,
          });
        } else if (response.data.code == 0 || response.data.code == -12 || response.data.code == -14) {
          commit("SELECTOPTION", {
            selectedValue: "scheduleReady",
            value: true,
          });
          commit("SELECTOPTION", {
            selectedValue: "errorFormReady",
            value: 1,
          });
        } else {
          commit("CALL_ERROR", -1);
          setTimeout(() => {
            commit("CALL_ERROR", 0);
          }, 5000);
          commit("SELECTOPTION", {
            selectedValue: "errorFormReady",
            value: 0,
          });
        }
      });
    },
    selectOption({ commit }, selection) {
      commit("SELECTOPTION", selection);
    },
    clearError({ commit }) {
      commit("CALL_ERROR", 0);
    },
    clearSelectedInfo({ commit }, page) {
      commit("CLEAR_SELECTEDINFO", page);
    },
    setCurrentStepIndex({ commit }, newIndex) {
      commit("updateCurrentStepIndex", newIndex);
    },
  },
});
