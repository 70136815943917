<template>
  <div>
    <div class="login" v-if="!isLoading && isAuthenticated">
      <div class="login-wrapper">
        <div class="logo">
          <img src="@/assets/iconlogo-branco 2.svg" />
        </div>
        <form @submit.prevent="login">
          <Input
            label="Nome de Utilizador"
            type="text"
            placeholder="Insira nome"
            @updateInput="updateInput($event, 'login')"
            @submit="login"
          />
          <Input
            label="Password"
            type="password"
            placeholder="Insira password"
            autocomplete="current-password"
            @updateInput="updateInput($event, 'password')"
            @submit="login"
          />
          <div v-if="hasError">
            <Error :error="hasError" />
          </div>
          <Button label="Entrar" @action="login" />
        </form>
      </div>
    </div>
    <div v-if="isLoading" :style="cssVars">
      <Loading type="screen" />
    </div>
  </div>
</template>

<script>
import Button from "@/components/Login/ButtonLogin.vue";
import Error from "@/components/Login/ErrorLogin.vue";
import Input from "@/components/Login/InputLogin.vue";
import Loading from "@/components/Loading/Loading.vue";

export default {
  name: "Home",
  components: {
    Button,
    Error,
    Input,
    Loading,
  },
  computed: {
    hasError() {
      return this.error;
    },
    isAuthenticated() {
      return this.$store.state.authenticated === 1;
    },
    isAuth() {
      return this.$store.state.authenticated;
    },
    cssVars() {
      return {
        "--primaryColor": this.$store.state.primaryColor
          ? this.$store.state.primaryColor
          : "#008fd1",
        "--secondaryColor": this.$store.state.secondaryColor
          ? this.$store.state.secondaryColor
          : "#008fd1",
        "--primaryTextColor": this.$store.state.primaryTextColor
          ? this.$store.state.primaryTextColor
          : "#ffffff",
        "--secondaryTextColor": this.$store.state.secondaryTextColor
          ? this.$store.state.secondaryTextColor
          : "#ffffff",
      };
    },
  },
  data() {
    return {
      isLoading: false,
      authenticator: {
        login: "",
        password: "",
      },
      error: "",
      showLogin:
        window.location.href === "http://vue.rigorcg.pt:9999/login" ||
        "http://demooutboundmsocaetanoretail.niw.pt/" ||
        "https://outboundmso.caetanoretail.pt/",
    };
  },
  methods: {
    updateInput(value, type) {
      this.authenticator[type] = value;
    },
    login() {
      this.isLoading = true;
      if (this.authenticator.login == "" || this.authenticator.password == "") {
        this.error = "Necessário inserir username e password!";
        setTimeout(() => {
          this.error = "";
        }, 4000);
      } else {
        this.$store
          .dispatch("authenticateLogin", this.authenticator)
          .then(() => {
            if (this.isAuth == 1) {
              this.$router.push("/Homepage");
            }
          });
					this.isLoading = false;
      }
    },
  },
  mounted() {
    if (document.referrer === "http://outboundmso.caetanoretail.pt/" || document.referrer === "http://demooutboundmsocaetanoretail.niw.pt/") {
      this.$router.push("/Login");
    }
  },
  watch: {
    isAuth() {
      if (this.isAuth < 0) {
        this.error = "Credenciais Erradas!";
        setTimeout(() => {
          this.error = "";
        }, 4000);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.login {
  height: 100vh;
  margin: 0;
  padding: 0;

  background: url("~@/assets/bg.png") no-repeat center center fixed;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;
}

.login-wrapper {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  .logo {
    text-align: center;
    margin-bottom: 3rem;
  }
  button {
    width: 300px;
    background: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 4px;

    padding: 0.9rem 0.5rem;
    outline: none;
    font-weight: 600;
    font-size: 1rem;
    text-align: center;
    cursor: pointer;
    opacity: 0.7;
    transition: all ease-in-out 300ms;

    &:hover {
      opacity: 1;
      box-shadow: 5px 5px 8px 0px rgba(0, 0, 0, 0.5);
    }
  }
}
</style>
