<template>
  <div class="message">
    <div class="wrapper">
      <div class="icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          class="bi bi-exclamation-triangle-fill"
          viewBox="0 0 16 16"
        >
          <path
            d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
          />
        </svg>
      </div>
      <div class="text">{{ error }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Error",
  props: {
    error: { type: String, default: "" },
  },
};
</script>
<style lang="scss" scoped>
.message {
  width: 300px;
  background: #fff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  color: #0056ce;
  border-radius: 4px;
  padding: 0.9rem 0.5rem;
  outline: none;
  font-size: 1rem;
  margin-bottom: 2rem;

  .wrapper {
    display: flex;

    .icon {
      display: flex;
      align-items: center;
      margin-right: 1rem;

      svg {
        width: 2rem;

        path {
          fill: red;
        }
      }
    }

    .text {
      display: flex;
      align-items: center;
      font-size: 1rem;
      font-weight: 600;
      color: red;
    }
  }
}
</style>
