import { country } from "@/services/country";

export function validateInputs(ids) {
  const errors = [];
  ids.forEach((id) => {
    const field = document.querySelector("#" + id);

    if (field.value != "") {
      if (id == "plate") {
        if (country == "pt") {
          if (!__IsPTPlate(field.value)) {
            errors.push(id);
          }
        } else if (country == "es") {
          if (!__IsESPlate(field.value)) {
            errors.push(id);
          }
        }
      } else if (id.includes("kms")) {
        if (!__IsNumber(field.value)) {
          errors.push(id);
        }
      } else if (id == "phone") {
        if (!__IsNumber(field.value) || !__isPTPhone(field.value)) {
          errors.push(id);
        }
      } else if (id == "email") {
        if (!__isEmail(field.value)) {
          errors.push(id);
        }
      }
    }
  });

  showErrors(ids, errors);
  return errors;
}

function showErrors(allIds, errors) {
  //REMOVE CLASS ERROR OF ALL INPUTS THAT REQUIRE VALIDATION (IN CASE IT HAD AN ERROR BEFORE AND DOESN'T ANYMORE)
  allIds.forEach((id) => {
    document.querySelector("#" + id).parentElement.classList.remove("error");
  });
  //ADD CLASS ERROR TO ALL INPUTS THAT DIDN'T PASS THE VALIDATION
  errors.forEach((error) => {
    document.querySelector("#" + error).parentElement.classList.add("error");
  });
}

/**
 * FUNCTIONS TO CHECK IF VALUE IS VALID
 */
function __IsPTPlate(value) {
  const plate = value.toUpperCase();
  let result = false;
  if (plate != "") {
    if (
      /* VALID PLATES:
      AA-00-AA | AA-00-00 | 00-AA-00 | 00-00-AA
      (with spaces or dashes or no spaces) */

      plate.match(
        new RegExp(
          "^(([A-Z]{2}-[0-9]{2}-([0-9]{2}|[A-Z]{2}))|([0-9]{2}-([0-9]{2}-[A-Z]{2}|[A-Z]{2}-[0-9]{2})))$"
        )
      ) ||
      plate.match(
        new RegExp(
          "^(([A-Z]{2}[0-9]{2}([0-9]{2}|[A-Z]{2}))|([0-9]{2}([0-9]{2}[A-Z]{2}|[A-Z]{2}[0-9]{2})))$"
        )
      ) ||
      plate.match(
        new RegExp(
          "^(([A-Z]{2} [0-9]{2} ([0-9]{2}|[A-Z]{2}))|([0-9]{2} ([0-9]{2} [A-Z]{2}|[A-Z]{2} [0-9]{2})))$"
        )
      )
    )
      result = true;
  }
  return result;
}

function __IsESPlate(value) {
  const plate = value.toUpperCase();
  let result = false;
  if (plate != "") {
    if (
      /*VALID PLATES:
      Old: L-NNNN-LL | LL-NNNN-LL | LLL-NNNN-LL
      New: NNNN-LLL
      (with spaces or dashes or no spaces)
      */
      plate.match(new RegExp("^[A-Z]{1,3} [0-9]{4} [A-Z]{2}$")) ||
      plate.match(new RegExp("^[A-Z]{1,3}-[0-9]{4}-[A-Z]{2}$")) ||
      plate.match(new RegExp("^[A-Z]{1,3}[0-9]{4}[A-Z]{2}$")) ||
      plate.match(new RegExp("^[0-9]{4} [A-Z]{3}$")) ||
      plate.match(new RegExp("^[0-9]{4}-[A-Z]{3}$")) ||
      plate.match(new RegExp("^[0-9]{4}[A-Z]{3}$"))
    )
      result = true;
  }
  return result;
}

function __IsNumber(value) {
  const number = value.replace(" ", "");
  let result = false;
  if (number != "") {
    if (number.match(/^(?:[+\d].*\d|\d)$/)) result = true;
  }
  return result;
}

function __isEmail(value) {
  return String(value)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

function __isPTPhone(value) {
  return value.length >= 9;
}
