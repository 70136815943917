<template>
  <div
    data-idgo-widget=""
    style="
      width: 0px !important;
      height: 2px !important;
      overflow: hidden;
      margin: 0;
    "
  ></div>
</template>

<script>
export default {
  data() {
    return {
      sessionID: this.$store.state.sessionCG,
    };
  },
  created() {
    this.loadScripts();
  },
  methods: {
    loadScripts() {
      return new Promise((resolve) => {
        let scriptEl = document.createElement("script");
        scriptEl.src = this.sessionID;
        scriptEl.type = "text/javascript";
        document.head.appendChild(scriptEl);

        scriptEl.addEventListener("load", () => {
          resolve();
        });
      });
    },
  },
};
</script>
