export let country = "es";

const url = window.location.host;
const urlArray = url.split(".");

if (urlArray[1] === "rigorcg") {
  if (urlArray[0] !== "vue") {
    country = urlArray[0].includes("espanha") ? "es" : "pt";
  }
} else {
  country = urlArray[urlArray.length - 1];
}
